import React, { useContext, useEffect, useState } from 'react'
import ImageCarousel from '../../UI/container/newsrow/image-carousel/ImageCarousel'
import Container from 'react-bootstrap/Container';
import styles from "./guides.module.css"
import Image1 from  "../../../src/images/EV 40-44.png"
import Image2 from  "../../../src/images/EV 45-49 Bloody.png"
import Image3 from  "../../../src/images/EV 45-49 Forest.png"
import Image4 from  "../../../src/images/EV 50-54.png"
import Image5 from  "../../../src/images/EV 55-59.png"
import Image6 from  "../../../src/images/EV 60-64.png"
import Image7 from  "../../../src/images/EV 65-69 1.png"
import Image8 from  "../../../src/images/EV 65-69 2.png"
import Image9 from  "../../../src/images/EV 70-74 1.png"
import Image10 from  "../../../src/images/EV 70-74 2.png"
import Image11 from  "../../../src/images/EV 75-79 1.png"
import Image12 from  "../../../src/images/EV 75-79 2.png"
import Image13 from  "../../../src/images/EV 75-79 3.png"


function EventurePage() {

  const [expandedImage, setExpandedImage] = useState(false)
  const expandImage = () => setExpandedImage(!expandedImage)



return (
  <div className={styles.content_column}>
    <ImageCarousel />

    <h2 className={styles.title}>Eventure System</h2>
    <div className='button_info'>

      <div className={styles.disclaimer}>

        <p className={styles.overlock_Font2}>
        The Eventure System, also known as EV, is filled with multiple activities where players fight each other (PvP) while trying to complete quests.
        Players who join and complete an eventure activity will earn EXP regardless of their final rank in the competition.
        </p>

        <p className={styles.overlock_Font2}>
        The System Announcement will notify players when they can sign up for an eventure activity 1 hour before it starts.
        To sign up, press the "H" key and check the "Activity" tab to see the list of all available activities. Select the one that best suits you and sign up.</p>
        <br />
        <p className={styles.overlock_Font2}>
            <b>Registration</b> from <u>14:00</u> to <u>15:00</u> & <b>Start Time</b> at <u>16:00</u> (Server Time)
          <br /><b>Registration</b> from <u>19:00</u> to <u>20:00</u> & <b>Start Time</b> at <u>21:00</u> (Server Time)
          <br /><b>Registration</b> from <u>8:00</u> to <u>9:00</u> & <b>Start Time</b> at <u>10:00</u> (Server Time)</p>
        <ol type="1">
        <li className={styles.overlock_Font}>The Very First Activity (System) Lv10-14</li>
        <li className={styles.overlock_Font}>Destination Gordon (System) Lv25-29</li>
        <li className={styles.overlock_Font}>Mutated Creatures (System) Lv40-44</li>
        <li className={styles.overlock_Font}>Defending the Black Market (System) Lv50-54</li>
        <li className={styles.overlock_Font}>Exo Beast Mutation (System) Lv65-69</li>
        </ol>


        <p className={styles.overlock_Font2}>
            <b>Registration</b> from <u>15:00</u> to <u>16:00</u> & <b>Start Time</b> at <u>17:00</u> (Server Time)
          <br /><b>Registration</b> from <u>20:00</u> to <u>21:00</u> & <b>Start Time</b> at <u>22:00</u> (Server Time)
          <br /><b>Registration</b> from <u>9:00</u> to <u>10:00</u> & <b>Start Time</b> at <u>11:00</u> (Server Time)</p>
        <ol type="1">
        <li className={styles.overlock_Font}>Accept New Challenges (System) Lv15-19</li>
        <li className={styles.overlock_Font}>Golden Fleece (System) Lv30-34</li>
        <li className={styles.overlock_Font}>Forest Bath (System) Lv45-49</li>
        <li className={styles.overlock_Font}>Breaking Through the Blockade (System) Lv55-59</li>
        <li className={styles.overlock_Font}>Battlefront Relaxation (System) Lv70-74</li>
        </ol>


        <p className={styles.overlock_Font2}>
            <b>Registration</b> from <u>16:00</u> to <u>17:00</u> & <b>Start Time</b> at <u>18:00</u> (Server Time)
            <br /><b>Registration</b> from <u>21:00</u> to <u>22:00</u> & <b>Start Time</b> at <u>23:00</u> (Server Time)
            <br /><b>Registration</b> from <u>10:00</u> to <u>11:00</u> & <b>Start Time</b> at <u>12:00</u> (Server Time)</p>
        <ol type="1">
        <li className={styles.overlock_Font}>Chiron's Treasure (System) Lv20-24</li>
        <li className={styles.overlock_Font}>A Troublesome Journey (System) Lv35-39</li>
        <li className={styles.overlock_Font}>A Bloody Battle (System) Lv45-49</li>
        <li className={styles.overlock_Font}>The Valley of Mysterious Love (System) Lv60-64</li>
        </ol>
        <br />

        <h4><span>Mutated Creatures (System) Lv40-44</span></h4>
        <p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Anteacar City of Magic</u></b>.
        <br />All 4 EV NPCs and quest objectives can be found in <b><u>Whispering Grave</u></b>.</p>
        <img src={Image1} />
        <br />
        <br />
        <p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Guardian Venomous Bee Lv37 General</li>
        <li className={styles.overlock_Font}>Experience Points: 130,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Guardian Venomous Bee Lv37 General</li>
        <li className={styles.overlock_Font}>Experience Points: 140,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Feral Soldier Bee Lv38 General</li>
        <li className={styles.overlock_Font}>Experience Points: 140,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 15 Graveyard Yellow-Robed Witch Lv40 Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 150,000</li></ol>

        <br />

<h4><span>Forest Bath (System) Lv45-49</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Anteacar City of Magic</u></b>.
<br />All 4 EV NPCs and quest objectives can be found in <b><u>Sacred Forest</u></b>.</p>
<img src={Image3} />
<br />
<br />
<p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 10 Frost Jester Lv41 General</li>
        <li className={styles.overlock_Font}>Experience Points: 330,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 10 Rebellious Musketeer Lv43 Very Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 400,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 15 Green-Robed Snow Witch Lv42 Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 300,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 5 Dagger Wound Ringleader Lv41 General</li>
        <li className={styles.overlock_Font}>Experience Points: 87,500</li></ol>


<br />

<h4><span>A Bloody Battle (System) Lv45-49</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Anteacar City of Magic</u></b>.
<br />All 4 EV NPCs and quest objectives can be found in <b><u>Sacred Realm of the God of War</u></b>.</p>
<img src={Image2} />
<br />
<br />
<p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
<ol type="2">
        <li className={styles.overlock_Font}>Kill 15 Blue-Robed Witch Lv41 Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 330,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 10 Thunder Clan Titan Rock Breaker Lv43 Super Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 400,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Malarial Venomous Bee Lv42 General</li>
        <li className={styles.overlock_Font}>Experience Points: 300,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Marshland Man-Eating Seedling Lv41 General</li>
        <li className={styles.overlock_Font}>Experience Points: 350,000</li></ol>

        <br />

<h4><span>Defending the Black Market (System) Lv50-54</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Gold Port City of Trade</u></b>.
<br />All 4 EV NPCs and quest objectives can be found in <b><u>Well of Silence</u></b>.</p>
<img src={Image4} />
<br />
<br />
<p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Cutthroat Robber Lv48 General</li>
        <li className={styles.overlock_Font}>Experience Points: 550,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Malicious Pipeline Worker Lv51 General</li>
        <li className={styles.overlock_Font}>Experience Points: 600,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Rogue Lv50 General</li>
        <li className={styles.overlock_Font}>Experience Points: 600,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 15 Outlaw Sniper Lv52 Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 700,000</li></ol>


        <br />

<h4><span>Breaking Through the Blockade (System) Lv55-59</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Gold Port City of Trade</u></b>.
<br />All 4 EV NPCs and quest objectives can be found in <b><u>Stormy Shore</u></b>.</p>
<img src={Image5} />
<br />
<br />
<p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Rogue Muscle Lv54 General</li>
        <li className={styles.overlock_Font}>Experience Points: 700,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Loose Parts Lv53 General</li>
        <li className={styles.overlock_Font}>Experience Points: 700,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 10 Anxious Artisian Lv52 Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 1,000,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Rotten Flower Lv54 General</li>
        <li className={styles.overlock_Font}>Experience Points: 1,000,000</li></ol>


        <br />

<h4><span>The Valley of Mysterious Love (System) Lv60-64</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Gold Port City of Trade</u></b>.
<br />All 4 EV NPCs and quest objectives can be found in <b><u>Icarus Rift</u></b>.</p>
<img src={Image6} />
<br />
<br />    
        <p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Unleashed Otherworldy Beast Lv57 General</li>
        <li className={styles.overlock_Font}>Experience Points: 800,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Winged Repairman Lv59 General</li>
        <li className={styles.overlock_Font}>Experience Points: 1,000,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Winged Guardian Lv59 General</li>
        <li className={styles.overlock_Font}>Experience Points: 1,000,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 15 Winged Cutter Lv62 Very Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 1,000,000</li></ol>


        <br />

<h4><span>Exo Beast Mutation (System) Lv65-69</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Gold Port City of Trade</u></b>.
<br />The first EV NPC and quest objective can be found in <b><u>The Heart of Fire.</u></b></p>
<img src={Image7} />
<br />
<br />
<p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Hard-Scaled Exo Beast Lv68 General</li>
        <li className={styles.overlock_Font}>Experience Points: 604,404</li></ol>


        <br />

<p className={styles.overlock_Font2}>The 3 remaining EV NPCs and quest objectives can be found in <b><u>Hephaestus Tableland</u></b>.</p>
<img src={Image8} />
<br />
<br />

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Bright-Scaled Exo Beast Lv69 General</li>
        <li className={styles.overlock_Font}>Experience Points: 604,404</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 20 Crystal-Scaled Exo Beast Lv70 General</li>
        <li className={styles.overlock_Font}>Experience Points: 604,404</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 5 Heavenly Fire Element Lv70 Super Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 604,404</li></ol>

        <br />

<h4><span>Battlefront Relaxation (System) Lv70-74</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Minotsize City of Industry</u></b>.
<br />The first 2 EV NPCs and quest objectives can be found in <b><u>Iron Fortress.</u></b></p>
<img src={Image9} />
<br />
<br />
        <p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Thunder Clan Frontliner Lv72 General</li>
        <li className={styles.overlock_Font}>Experience Points: 775,345</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Campsite Sentry Lv72 General</li>
        <li className={styles.overlock_Font}>Experience Points: 775,345</li></ol>


        <br />

<p className={styles.overlock_Font2}>The 2 remaining EV NPCs and quest objectives can be found in <b><u>Titan Ruin</u></b>.</p>
<img src={Image10} />
<br />
<br />

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 15 Leprechaun Warrior Lv72 Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 775,345</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font6}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 10 Industrious Servant Lv73 Very Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 775,345</li></ol>


        <br />

<h4><span>Chaos in Istanmos (System) Lv75-79</span></h4>
<p className={styles.overlock_Font2}>Start the activity at the <i>Joyful Mailman</i> in <b><u>Crystalia City of Military</u></b>.
<br />The first 2 EV NPCs and quest objectives can be found in <b><u>Venom Wilderness.</u></b></p>
<img src={Image11} />
<br />
<br />
        <p className={styles.overlock_Font2}><b>Eventure NPC 1 ➙</b> Located at the <span className={styles.overlock_Font3}><b>blue</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 15 Savage Therianthropy Lv75 Very Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 550,000</li></ol>

        <p className={styles.overlock_Font2}><b>Eventure NPC 2 ➙</b> Located at the <span className={styles.overlock_Font4}><b>red</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 10 Steel Armored Kaizoku Maru Lv75 Very Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 550,000</li></ol>


        <br />

<p className={styles.overlock_Font2}>The 3rd EV NPC and quest objective can be found in <b><u>Dead Water Wasteland</u></b>.</p>
<img src={Image12} />
<br />
<br />

        <p className={styles.overlock_Font2}><b>Eventure NPC 3 ➙</b> Located at the <span className={styles.overlock_Font5}><b>green</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 25 Crimson Fierce Mela Lv75 Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 520,000</li></ol>


        
        <br />

<p className={styles.overlock_Font2}>The 4th and last EV NPC and quest objective can be found in <b><u>Misty Forest</u></b>.</p>
<img src={Image13} />
<br />
<br />

        <p className={styles.overlock_Font2}><b>Eventure NPC 4 ➙</b> Located at the <span className={styles.overlock_Font5}><b>turquoise</b></span> clown icon.</p>
        <ol type="2">
        <li className={styles.overlock_Font}>Kill 10 Tiny Mela Lv75 Very Strong</li>
        <li className={styles.overlock_Font}>Experience Points: 530,000</li></ol>

      </div>
    </div>

  

    



    
  </div>
)
}

export default EventurePage