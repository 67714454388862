import React, { useContext } from 'react'
import { Carousel } from 'react-bootstrap'
import banner1 from "../../../../images/banner 1.png"
import banner2 from "../../../../images/banner 2.png"
import banner3 from "../../../../images/banner 3.png"
import banner9 from "../../../../images/banner 9.png"
import banner10 from "../../../../images/banner 10.png"
import banner11 from "../../../../images/banner 11.png"
import banner12 from "../../../../images/banner 12.png"
import banner13 from "../../../../images/banner 13.png"
import banner16 from "../../../../images/banner 16.png"
import banner15 from "../../../../images/banner 15.png"
import banner17 from "../../../../images/banner 17.png"
import banner18 from "../../../../images/banner 18.png"
import banner19 from "../../../../images/banner 19.png"
import banner20 from "../../../../images/banner 20.png"
import banner21 from "../../../../images/banner 21.png"
import styles from "../../container.module.css"
import { AuthContext } from '../../../../context/authContext'

function ImageCarousel() {
  const banners = [banner21, banner13, banner1, banner2, banner3]

  const { currentUser } = useContext(AuthContext)
  return (
    <Carousel slide={false} className={styles.carousel}>

      {banners.map((banner, i) => (
        <Carousel.Item key={banners[i]}>
          <img
            className={styles.carousel_image}
            src={banner}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}

    </Carousel>
  )
}

export default ImageCarousel
